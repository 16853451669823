@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

li {
  font-size: 14px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  line-height: 1.25;
  color: var(--darkBlue);
}

h1,
.h1 {
  font-size: calc(1.475rem + 2.7vw);
}

@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 3.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.425rem + 2.1vw);
}

@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 3rem;
  }
}

h3,
.h3 {
  font-size: calc(1.355rem + 1.26vw);
}

@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 2.3rem;
  }
}

h4,
.h4 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.75rem;
  }
}

h5,
.h5 {
  font-size: calc(1.255rem + 0.06vw);
}

@media (min-width: 1200px) {
  h5,
  .h5 {
    font-size: 1.3rem;
  }
}

h6,
.h6 {
  font-size: 1rem;
}

.__fw {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  height: auto !important;
  background-color: #00273a;
}

.__mw {
  max-width: 1200px !important ;
  // padding: 0px 20px !important;
  padding-right: calc(var(--bs-padding) * 0.5);
  padding-left: calc(var(--bs-padding) * 0.5);
  display: flex !important;
  height: auto !important;
  width: 100% !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}


/* Custom Styling for the Select Dropdown */
.select-dropdown {
  appearance: none;
  -webkit-appearance: none; /* Safari */
  -moz-appearance: none; /* Firefox */
  background-color: #fff; /* Ensure it has a solid background */
  padding: 10px;
  font-size: 16px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.select-dropdown option:first-child {
  display: none; /* Hide the first empty option */
}

input {
  transform: "scale(1.0)";
  font-size: 14px !important;
}
