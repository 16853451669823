.pt_grid{
  margin-top: 4rem;
  margin-bottom: 2rem;

  .loading{
    width: 100%;
    display: flex;
    min-height:80dvh ;
    align-items: center;
    justify-content: center;
  }
  .data_gird{
     background-color: white;
       padding: 1rem;
  }
}
.con_wrp{
  width: 100%;
  min-height: 80dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem 0rem;
  flex-direction: column;
  .getaForm{
    // width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    input {
      padding: 0.5rem;
      font-size: 1.25em;
      text-align: center;
      border-radius: 30px;
      border: 0;
      outline: none;
    }

  }
  .data_list{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    ul{
        padding-left:0px
    }
  
    .win_card{
      padding: 2rem;
      background-color: white;
      border-radius: 1rem;
      margin-top: 10px;
      align-items: flex-start;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      width: 400px;

      
      h3{
        color: black !important;
        font-weight: bold !important;
      }

    }
    
  }
}