.home {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 100dvh;
  //border: 1px solid rgb(15, 54, 87);
  border-radius: 1rem;
  padding: 2rem;
  flex-direction: column;
  max-width: 600px;
  
  .age {
    width: 100%;
    margin-top: 1rem;
    max-width: 600px;
    select {
      border-radius: 0.7rem;
      border: 1px solid rgba(255, 255, 255, 0.229);
      width: 100%;
      padding: 13px 30px;
      display: flex;
      outline: none;
      font-size: 16px;
      background-color: transparent;
      color: white;
    }
    .sec_wrp {
      margin-bottom: 1rem;
      width: 400px;
      display: flex;
      border-radius: 0.5rem;
      align-items: center;
      justify-content: center;
      height: 3.5rem;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0);
      border: 1px solid rgba(255, 255, 255, 0.229);
      h5 {
        margin-bottom: 0;
        color: rgb(255, 255, 255);
        font-weight: 500;
      }
    }
    .sec_wrp:hover {
      background-color: #80ef80a5;
    }
    .sec_wrp :focus {
      background-color: rgba(0, 128, 0, 0.422);
    }
  }

  .country {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    margin-top: 1rem;
    
  }
  .text_area {
    width: 100%;
    outline: none;
    overflow: hidden;
    border-radius: 0.5rem;
    //background-color: rgba(255, 255, 255, 0.347);
    //width: 400px;
    textarea {
      border: 1px solid rgba(255, 255, 255, 0.229);
      width: 100%;
      border-radius: 0.5rem;
      background-color: transparent;
      color: white;
      outline: none;
      padding: 10px;
    }
  }
}
.title {
  background-color: rgba(255, 255, 255, 0.577) !important;
}

.search_in {
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  //background-color: white !important;
  border-radius: 0.5rem;
  min-height: calc(2em + 1.6rem + 2px) !important;
  // padding: 0.8rem 1.5rem !important;
  position: relative;
  border: 1px solid var(--braown);
  input {
    //width: 400px;
    padding: 14px 30px;
    outline: none;
    background-color: rgba(255, 255, 255, 0.347);
    border: 1px solid rgba(255, 255, 255, 0.229);
    font-size: 16px;
    border-radius: 0.5rem;
    background-color: transparent;
    color: white ;
  }
  .country_list {
    position: absolute;
    display: flex;
    width: 100%;
    top: 58px;
    z-index: 999;

    ul {
      padding: 20px;
      width: 100%;
      list-style-type: none;
      padding: 0;
      margin: 0;
      padding-bottom: 20px;
      top: 100%;
      left: 0;
      right: 0;
      max-height: 200px;
      overflow-y: auto;
      background-color: #ffffff;
      opacity: 1;
      z-index: 1000;
      border-radius: 0.7rem;
      margin-top: 5px;

      -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
      box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
      li {
        cursor: pointer;
        padding: 5px 5px 5px 20px;
        font-size: 16px;
        border-left: 2px solid white;
      }
      li:hover {
        background-color: var(--bgColor);
        border-left: 2px solid var(--braown);
        transition: var(--transition);
      }
    }
  }
}

.head {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  h5 {
    color: white;
    margin: 0;
    font-weight: 400;
    font-size: 17px;
  }
}
.active {
  //width: 400px;
  height: 20px;
  padding: 10px 30px;
  background-color: rgba(0, 128, 0, 0.433);
}
.email_ {
  width: 100%;
  margin-top: 1rem;
  input {
  width: 100% !important;
    padding: 14px 30px;
    outline: none;
    background-color: rgba(255, 255, 255, 0.347);
    border: 1px solid rgba(255, 255, 255, 0.229);
    font-size: 16px;
    border-radius: 0.5rem;
    background-color: transparent;
    color: white ;
  }
}
.button {
  width: 100%;
  margin-top: 1rem;
  outline: none;
  border: none;
  background: transparent;
  overflow: hidden;
  span {
    width: 100%;
    background-color: rgba(255, 166, 0, 0.755);
    height: auto;
    display: flex;
    border-radius: 0.5rem;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
  }
}
.button :hover {
  cursor: pointer;
  width: 100%;
   border-radius: 0.5rem;
  background-color: rgb(255, 166, 0);
}

.button-group {
  display: flex;
  gap: 10px;
  flex-direction: column;
  button {
    background-color: transparent;
    color: white;
  }
}

.sec_wrp {
  flex: 1;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.229);
  //background-color: #f8f8f8;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.sec_wrp.active {
  background-color: rgba(0, 128, 0, 0.433);
  color: white;
  border-color: rgba(0, 199, 0, 0.863);
}
.location{
  p{
    color: white;
  }
}
input{
  display: flex;
  width: 100%;
}

.heading{
  display: flex;
  img{
    width: 300px;
  }
}

.error_wrp{
  height: 50px;
  margin-top: .5rem;
}
select option:first-child {
  color: grey !important;
}